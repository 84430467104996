@font-face {
    font-family: 'MetalMania-Regular'; /* Вкажіть назву вашого шрифту */
    src: url('../fonts/MetalMania-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  body {
    font-family: 'MetalMania-Regular', sans-serif;
  }
  