// src/styles/App.scss

@import '../styles/fonts';

.app {
    background-image: url('../images/main_bg.png'); // Replace with your actual image path
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;

    .logo {
        position: absolute;
        top: 35px;
        left: 120px;
        cursor: pointer;
        z-index: 2;

        @media screen and (max-width: 1440px) {
            top: 30px;
            left: 50px;
        }

        @media screen and (max-width: 768px) {
            height: 45px;
            width: 105px;
            top: 30px;
            left: 25px;
        }
    }

    .centered-images {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-direction: row;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        .vihor {
            width: 700px;
            height: auto;
            border-radius: 10px;

            // Додаємо анімацію обертання
            animation: rotate 10s linear infinite;
        }
    }

    .ghosts {
        z-index: 1;

        .ghost-1,
        .ghost-2 {
            position: absolute;
            width: 443px;
            height: auto;
        }

        .ghost-1 {
            left: 100px;
            bottom: 50px;
        }

        .ghost-2 {
            right: 0;
            bottom: 50px;
        }

        @media (max-width: 1440px) {

            .ghost-1,
            .ghost-2 {
                width: 343px;
            }

            .ghost-1 {
                left: 80px;
                bottom: 40px;
            }

            .ghost-2 {
                right: 20px;
                bottom: 40px;
            }
        }

        @media (max-width: 1024px) {

            .ghost-1,
            .ghost-2 {
                display: none;
            }
        }

    }
}

// Анімація для обертання
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}