*{
  padding: 0;
  margin: 0;
}

a{
  color: #fff;
  text-decoration: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
