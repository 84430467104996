// src/styles/Footer.scss

.footer {
    display: flex;
    justify-content: center;
    gap: 120px;
    padding: 20px;
    font-size: 0.8rem;
    background-color: rgba(0, 0, 0, 0.138);
    z-index: 555;
  
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      font-size: 0.7rem;
    }
  
    @media (max-width: 480px) {
      font-size: 0.6rem;
    }
  
    p, a {
      margin: 0;
      color: #9E9E9E;
    }
  }
  